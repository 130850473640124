import PropTypes from "prop-types"
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import "./header.css"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: {eq: "wh-coloredlogo.png"}) {
        id
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  
  return (
    <>
    <div className="header">
      <div className="header-left">
        <Link to="/">
         <Img fixed={data.image.childImageSharp.fixed} alt={siteTitle}/>
        </Link>
        <Link to="/langkawi"><div className="first button">Langkawi</div></Link>
        <Link to="/penang"><div className="button">Penang</div></Link>
      </div>
      <div className="header-right">
      {/* <div className="countdown"><span style={{fontWeight: "bold", color:"#f02323"}}>SALES RENTAS NEGERI</span> ends in <CountdownTimer/></div> */}
        <a href="#form"><div className="button">Book Now</div></a>
      </div>
    </div>
    <div className="header-mobile">
      <Link to="/"><Img fixed={data.image.childImageSharp.fixed} alt={siteTitle}/></Link>
      <a href="#form"><button className="mobile-button">Book Now</button></a>
      {/* <div className="countdown"><span style={{fontWeight: "bold", color:"#f02323"}}>SALES RENTAS NEGERI</span> ends in <CountdownTimer/></div> */}
    </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header